import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  type DtoProgram,
  EnumsMessageCampaignType,
  EnumsMessageTarget,
  EnumsMessageTriggerEventName,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import {
  type MessageCampaign,
  type MessageTriggerConfig,
  type MessageVars,
} from '../../Message';
import { useUser } from '../../UserContext';

enum Tags {
  RecognitionWeeklyReport = 'recognitionWeeklyReport',
}

export function useMessageCampaignProgramRoundRecognition(props: {
  program: DtoProgram;
  programLink?: DtoChannelProgramLink;
  channel?: DtoChannel;
}): MessageCampaign {
  const { program } = props;

  const trigger: MessageTriggerConfig = useInstance(() => ({
    types: [
      EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
      EnumsMessageTriggerType.MessageTriggerTypeEventDriven,
    ],
    events: [
      EnumsMessageTriggerEventName.MessageTriggerEventNameRecognitionGiven,
      EnumsMessageTriggerEventName.MessageTriggerEventNameRecognitionReceived,
    ],
  }));

  const targets = useLiveCallback((trigger: ModelsMessageTrigger) => {
    if (
      trigger.type === EnumsMessageTriggerType.MessageTriggerTypeEventDriven
    ) {
      return [EnumsMessageTarget.MessageTargetMember];
    }
    return [
      EnumsMessageTarget.MessageTargetOrgAdmins,
      EnumsMessageTarget.MessageTargetOrgMembers,
    ];
  });

  const tags = useLiveCallback(
    (trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      if (
        trigger.type === EnumsMessageTriggerType.MessageTriggerTypeTimeOffset
      ) {
        return [Tags.RecognitionWeeklyReport];
      }
      return [];
    }
  );

  const vars = useLiveCallback(
    (
      trigger: ModelsMessageTrigger,
      _target: EnumsMessageTarget,
      tags: string[]
    ) => {
      const vars: MessageVars = {
        text: {},
        media: {},
        condition: {},
      };

      if (
        trigger.type === EnumsMessageTriggerType.MessageTriggerTypeEventDriven
      ) {
        vars.text = {
          channelName: '<#lunapark>',
          chosenEmoji: '🌮',
          chosenEmojiName: 'taco',
          dailyRemainingAmountBeforeGiven: 4,
          dailyRemainingAmountAfterGiven: 3,
          attemptedGivingAmount: 1,
          timeUntilReset: '5 hours and 30 minutes',
          senderName: '<@sender>',
          receiverName: '<@receiver>',
          receivedAmount: 1,
        };
        vars.condition = {
          dailyRemainingAmountBeforeGiven: 0,
          attemptedGivingAmount: 0,
        };
      } else {
        vars.text = {
          chosenEmoji: '🌮',
          chosenEmojiName: 'taco',
          installedChannels: '<#lunapark>\n<#lunapark2>',
          // membersDailyGivingAmount: 5,
          notificationSettingsLink: `${window.origin}/notifications/settings`,
        };
        vars.condition = {
          userIsAdmin: false,
          userEngagedThisWeek: false,
          roundNumber: 1,
        };
        if (tags?.includes(Tags.RecognitionWeeklyReport)) {
          vars.text.thisWeekTotal = 10;
          vars.text.lastWeekTotal = 8;
          vars.text.topSendersThisWeek =
            'Top givers this week:\n1). user1 `10`\n2). user2 `8`\n3). user3 `6`';
          vars.text.topReceiversThisWeek =
            'Top receivers this week:\n1). user1 `10`\n2). user2 `8`\n3). user3 `6`';
          vars.text.thisMonthTotal = 10;
          vars.text.lastMonthTotal = 8;
          vars.text.topSendersThisMonth =
            'Top givers this month:\n1). user1 `10`\n2). user2 `8`\n3). user3 `6`';
          vars.text.topReceiversThisMonth =
            'Top receivers this month:\n1). user1 `10`\n2). user2 `8`\n3). user3 `6`';

          vars.text.weekDateRange = 'Dec 31 - Jan7';
        }
      }
      return vars;
    }
  );

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
      id: program.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [program.id, targets, trigger, tags, vars]
  );
}

export function useMessageCampaignRecognitionProgramActivate(props: {
  program: DtoProgram;
}) {
  const { program } = props;

  const user = useUser();

  const trigger: MessageTriggerConfig = useMemo(
    () => ({
      types: [EnumsMessageTriggerType.MessageTriggerTypeTimeOffset],
    }),
    []
  );

  const targets = useCallback(() => {
    return [
      EnumsMessageTarget.MessageTargetChannelCreator,
      EnumsMessageTarget.MessageTargetChannel,
      // EnumsMessageTarget.MessageTargetChannelMembers,
      // EnumsMessageTarget.MessageTargetOrgAdmins,
    ];
  }, []);

  const tags = useLiveCallback(
    (_trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      return [];
    }
  );

  const vars = useCallback(() => {
    const vars: MessageVars = {
      text: {
        recipientName: `<@${user.username}>`,
        programDetailsLink: window.origin,
        programAdminLink: window.origin,
        channelName: '#lunapark',
        nextRoundDate: format(new Date(), 'EEE, MMM do'),
        membersDailyGivingAmount: 5,
        adminDailyGivingAmount: 5,
      },
      media: {
        topicMedia: null,
      },
      condition: {},
    };
    return vars;
  }, [user.username]);

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramLinkActivated,
      id: program.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [program.id, targets, trigger, tags, vars]
  );
}
