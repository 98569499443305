import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';

import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { type MessageLogicFormData } from './MessageLogicEditor';

function tagValueToOption(tag: string): Option<string> {
  return { label: tag, value: tag };
}

export function MessageTagsEditor(props: {
  supportTags: string[];
}): JSX.Element | null {
  const { supportTags } = props;

  const {
    field: { value: selectedTags, onChange },
  } = useController<MessageLogicFormData, 'tags'>({
    name: 'tags',
  });

  const options = useMemo(
    () => supportTags.map(tagValueToOption),
    [supportTags]
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<string>, true>({
      override: { control: { height: 48 } },
    })
  );

  if (supportTags.length === 0 && selectedTags.length === 0) return null;

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>Tags</div>
      <Select<Option<string>, true>
        isMulti
        options={options}
        value={selectedTags.map(tagValueToOption)}
        classNamePrefix='select-box-v2'
        className='w-1/2'
        styles={styles}
        placeholder='Select tags'
        onChange={(options) => {
          onChange(options.map((o) => o.value));
        }}
      />
    </div>
  );
}
