import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { NotificationCenter } from '../components/Notification';
import { ProgramEditor } from '../components/Program';
import { AdminView } from '../pages/Admin/AdminView';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });
  const url = new URL(action.request.url);
  const backTo = url.searchParams.get('back-to');

  const [program, rounds] = await tokenWithRedirect(
    async () =>
      Promise.all([
        (await apiService.program.getProgram(id)).data.program,
        (await apiService.program.queryRounds(id)).data.programRounds,
      ]),
    action.request.url,
    { admin: true }
  );

  return {
    program,
    rounds,
    backTo,
  };
}

export function Component() {
  const { program, rounds, backTo } = useLoaderData<typeof clientLoader>();
  return (
    <AdminView className='bg-library-2023-07'>
      <ProgramEditor program={program} rounds={rounds} backTo={backTo} />;
      <NotificationCenter
        className='!w-108'
        position='top-center'
        toastClassName='rounded my-2'
      />
    </AdminView>
  );
}
