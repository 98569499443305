import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { $path } from 'remix-routes';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type CelebrationsProgram } from '../../../types/program';
import { ProgramEditorLayout } from '../ProgramEditorLayout';
import { type BasicProgramFormData } from '../types';
import { CelebrationsProgramMessageCampaign } from './CelebrationsProgramMessages';

type FormData = BasicProgramFormData;

function makeDefaultValues(program: CelebrationsProgram): FormData {
  return {
    name: program.name,
    basicSettings: program.basicSettings,
    promotionalAssets: program.promotionalAssets,
    marketingMetadata: program.marketingMetadata,
  };
}

export function CelebrationsProgramEditor(props: {
  program: CelebrationsProgram;
  backTo?: string | null;
}) {
  const { program, backTo } = props;

  const navigate = useNavigate();

  const form = useForm<FormData>({
    defaultValues: makeDefaultValues(program),
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onClose = () => {
    navigate(backTo || $path('/admin/programs/v2'));
  };

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: FormData) => {
    const resp = await apiService.program.updateProgram(program.id, {
      name: data.name,
      basicSettings: data.basicSettings ?? undefined,
      promotionalAssets: data.promotionalAssets ?? undefined,
      marketingMetadata: data.marketingMetadata ?? undefined,
    });
    return resp.data.program;
  });

  const onSubmit = () => {
    form.handleSubmit(async (data: FormData) => {
      const resp = await submit(data);
      if (!resp) return;
      onClose();
    })();
  };

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  return (
    <ProgramEditorLayout
      type={program.type}
      program={program}
      onCancel={onClose}
      onSubmit={onSubmit}
      disabledReason={disabledReason}
      errors={errors}
      resetErrors={resetSubmit}
      setIsUploading={setIsUploading}
      {...form}
    >
      <FormProvider {...form}>
        <div className='flex flex-col gap-15 w-full text-white p-10'>
          <CelebrationsProgramMessageCampaign program={program} />
        </div>
      </FormProvider>
    </ProgramEditorLayout>
  );
}
