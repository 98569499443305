import { useController, useFieldArray } from 'react-hook-form';

import {
  fromMediaDataDTO,
  fromMediaDTO,
  toMediaDataDTO,
  toMediaDTO,
} from '../../utils/api-dto';
import { uuidv4 } from '../../utils/common';
import { DragDropList } from '../common/DragDrop';
import { LoadableFAQGroupsEditor } from '../FAQ/FAQGroupList';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { MiniMediaEditor } from '../MediaUploader/MiniMediaEditor';
import { type BasicProgramControlledProps } from './BasicProgramEditor';
import { type BasicProgramFormData } from './types';

function FAQGroupsField(props: BasicProgramControlledProps) {
  const {
    field: { value: ids, onChange },
  } = useController({
    name: 'marketingMetadata.faqGroupIds',
    control: props.control,
  });

  return (
    <LoadableFAQGroupsEditor
      title='FAQ Groups'
      ids={ids || []}
      onChange={onChange}
    />
  );
}

function AnonymousFAQGroupsField(props: BasicProgramControlledProps) {
  const {
    field: { value: ids, onChange },
  } = useController({
    name: 'marketingMetadata.anonymousFAQGroupIds',
    control: props.control,
  });

  return (
    <LoadableFAQGroupsEditor
      title='Anonymous FAQ Groups'
      ids={ids || []}
      onChange={onChange}
    />
  );
}

function TutorialField(props: BasicProgramControlledProps) {
  const { fields, move, append, remove, update } = useFieldArray<
    BasicProgramFormData,
    'marketingMetadata.tutorials',
    'key'
  >({
    control: props.control,
    name: 'marketingMetadata.tutorials',
    keyName: 'key',
  });

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>How does it works</div>

      <div className='flex flex-col gap-1'>
        <DragDropList
          type='program-tutorial'
          items={fields}
          onMove={move}
          render={({ item, index, drag, ref, style }) => (
            <div
              className={`w-full p-3 rounded-xl bg-layer-002 flex flex-col items-start gap-3`}
              ref={ref}
              style={style}
            >
              <div className='w-full flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <button type='button' ref={drag} className='btn cursor-move'>
                    <MenuIcon className='w-3.5 h-3.5 fill-current' />
                  </button>
                  <p className='text-sms font-bold'>{`Slide ${index + 1}`}</p>
                </div>
                <button
                  type='button'
                  onClick={() => remove(index)}
                  className='btn text-xs text-red-002'
                >
                  <DeleteIcon className='w-3.5 h-3.5 fill-current' />
                </button>
              </div>

              <div>
                <MiniMediaEditor
                  id={`tutorial-media-${item.key}`}
                  video
                  media={fromMediaDTO(item.media?.media)}
                  mediaData={fromMediaDataDTO(item.media?.data)}
                  objectFit='cover'
                  onChange={(data, media) =>
                    update(index, {
                      ...item,
                      media: {
                        media: media ? toMediaDTO(media) : null,
                        data: toMediaDataDTO(data),
                      },
                    })
                  }
                />
              </div>

              <textarea
                value={item.description}
                onChange={(e) => {
                  const description = e.target.value;
                  update(index, { ...item, description });
                }}
                className='field w-full py-2 min-h-21 mb-0'
                placeholder='Max 300 characters'
                maxLength={300}
              />
            </div>
          )}
        />
      </div>

      <button
        type='button'
        onClick={() => append({ id: uuidv4(), description: '', media: null })}
        className='mt-2 btn text-xs text-white underline'
      >
        + Add Slide
      </button>
    </div>
  );
}

function StatsField(props: BasicProgramControlledProps) {
  const { fields, move, append, remove, update } = useFieldArray<
    BasicProgramFormData,
    'marketingMetadata.stats',
    'key'
  >({
    control: props.control,
    name: 'marketingMetadata.stats',
    keyName: 'key',
  });

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>Big number data</div>

      <div className='flex flex-col gap-1'>
        <DragDropList
          type='program-stat'
          items={fields}
          onMove={move}
          render={({ item, index, drag, ref, style }) => (
            <div
              className={`w-full p-3 rounded-xl bg-layer-002 flex flex-col items-start gap-3`}
              ref={ref}
              style={style}
            >
              <div className='w-full flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <button type='button' ref={drag} className='btn cursor-move'>
                    <MenuIcon className='w-3.5 h-3.5 fill-current' />
                  </button>
                  <p className='text-sms font-bold'>{`Data ${index + 1}`}</p>
                </div>
                <button
                  type='button'
                  onClick={() => remove(index)}
                  className='btn text-xs text-red-002'
                >
                  <DeleteIcon className='w-3.5 h-3.5 fill-current' />
                </button>
              </div>

              <input
                value={item.value}
                onChange={(e) => {
                  const value = e.target.value;
                  update(index, { ...item, value });
                }}
                className='field w-full mb-0'
                placeholder='Add number'
              />

              <textarea
                value={item.description}
                onChange={(e) => {
                  const description = e.target.value;
                  update(index, { ...item, description });
                }}
                className='field w-full py-2 min-h-21 mb-0'
                placeholder='Max 300 characters'
                maxLength={300}
              />
            </div>
          )}
        />
      </div>

      <button
        type='button'
        onClick={() => append({ id: uuidv4(), value: '', description: '' })}
        className='mt-2 btn text-xs text-white underline'
        disabled={fields.length >= 3}
      >
        + Add Data
      </button>
    </div>
  );
}

export function BasicProgramMarketingEditor(
  props: BasicProgramControlledProps
) {
  return (
    <div className='flex flex-col gap-4'>
      <FAQGroupsField {...props} />
      <AnonymousFAQGroupsField {...props} />
      <TutorialField {...props} />
      <StatsField {...props} />
    </div>
  );
}
