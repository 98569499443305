import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import config from '../../config';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { OrganizerPicker } from '../Organization/OrganizerPicker';

interface FormData {
  recipient: Organizer | null;
}

export function SendProgramPromotionForm(props: {
  onClose: () => void;
  onConfirm: (data: FormData) => Promise<void>;
}) {
  const { control, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      recipient: null,
    },
  });

  const {
    call: submit,
    state: { error },
  } = useLiveAsyncCall(async (data: FormData) => {
    await props.onConfirm(data);
  });

  return (
    <form
      className='w-full px-5 py-7.5 text-white'
      onSubmit={handleSubmit(submit)}
    >
      <header className='text-2xl text-center font-medium'>
        Send Program Promotion Preview
      </header>

      <main className='mt-10 flex flex-col gap-7.5'>
        <p className='text-center italic'>
          Note: Any unsaved modifications will not be reflected in the message.
        </p>

        <div>
          <h3 className='text-base font-bold mb-1'>Send to</h3>

          <Controller
            control={control}
            name='recipient'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <OrganizerPicker
                orgId={config.misc.lunaParkOrgId}
                onChange={onChange}
                defaultOrganizer={value || undefined}
                placeholder='Select a member in Luna Park organization'
                className='h-15'
              />
            )}
          />
        </div>
      </main>

      <footer className='mt-10 relative'>
        <div className='w-full mb-1 text-center text-red-500 text-sm'>
          {err2s(error)}
        </div>
        <div className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-40 h-10'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn-primary w-40 h-10 flex justify-center items-center'
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? 'Sending...' : 'Send'}
          </button>
        </div>
      </footer>
    </form>
  );
}

export function useTriggerSendProgramPromotionModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: { onConfirm: (data: FormData) => Promise<void> }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-160'
            borderStyle='white'
            onClose={p.internalOnCancel}
          >
            <SendProgramPromotionForm
              onConfirm={async (data) => {
                p.internalOnConfirm();
                await props.onConfirm(data);
              }}
              onClose={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}
