import { EnumsProgramType } from '@lp-lib/api-service-client/public';

import {
  type CalendarProgramRound,
  castProgram,
  castProgramRounds,
  type IntrosProgramRound,
  type WaterCoolerProgramRound,
} from '../../types/program';
import { assertExhaustive } from '../../utils/common';
import { CalendarProgramEditor } from './Calendar';
import { CelebrationsProgramEditor } from './Celebrations';
import { DefaultProgramEditor } from './DefaultProgramEditor';
import { IntrosProgramEditor } from './Intros';
import { RecognitionProgramEditor } from './Recognition';
import { type ProgramEditorProps } from './types';
import { WaterCoolerProgramEditor } from './WaterCooler';

export function ProgramEditor(props: ProgramEditorProps): JSX.Element | null {
  const { program, rounds, backTo } = props;

  switch (program.type) {
    case EnumsProgramType.ProgramTypeCalendar:
      return (
        <CalendarProgramEditor
          key={program.id}
          program={program}
          rounds={castProgramRounds<CalendarProgramRound>(rounds)}
          backTo={backTo}
        />
      );
    case EnumsProgramType.ProgramTypeIntros:
      return (
        <IntrosProgramEditor
          key={program.id}
          program={program}
          rounds={castProgramRounds<IntrosProgramRound>(rounds)}
          backTo={backTo}
        />
      );
    case EnumsProgramType.ProgramTypeWaterCooler:
      return (
        <WaterCoolerProgramEditor
          key={program.id}
          program={castProgram(program)}
          rounds={castProgramRounds<WaterCoolerProgramRound>(rounds)}
          backTo={backTo}
        />
      );
    case EnumsProgramType.ProgramTypeRecognition:
      return <RecognitionProgramEditor key={program.id} {...props} />;
    case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
      return (
        <CelebrationsProgramEditor
          key={program.id}
          program={program}
          backTo={backTo}
        />
      );
    case EnumsProgramType.ProgramTypeGameDrops:
    case EnumsProgramType.ProgramTypeGlobalPairing:
    case EnumsProgramType.ProgramTypeAiSurvey:
    case EnumsProgramType.ProgramTypeCustomTournament:
      return (
        <DefaultProgramEditor
          key={program.id}
          program={program}
          backTo={backTo}
        />
      );
    case EnumsProgramType.ProgramTypeDummy:
      return null;
    default:
      assertExhaustive(program.type);
      return null;
  }
}
