import { useNavigate } from '@remix-run/react';
import shuffle from 'lodash/shuffle';
import uniqBy from 'lodash/uniqBy';
import { useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { $path } from 'remix-routes';

import {
  type ModelsWaterCoolerProgramExtensions,
  type ModelsWaterCoolerRoundExtensions,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import {
  type WaterCoolerProgram,
  type WaterCoolerProgramRound,
} from '../../../types/program';
import { ProgramCategoriesEditor } from '../ProgramCategories';
import { ProgramEditorLayout } from '../ProgramEditorLayout';
import { type BasicProgramFormData } from '../types';
import { ProgramUtils } from '../utils';
import { type WaterCoolerTopic } from './types';
import { WaterCoolerProgramUtils } from './utils';
import { WaterCoolerProgramMessageCampaign } from './WaterCoolerProgramMessages';
import { type WaterCoolerTopicFormData } from './WaterCoolerTopicEditor';
import { WaterCoolerTopicQueue } from './WaterCoolerTopicQueue';

function TopicSection(props: { program: WaterCoolerProgram }) {
  const { program } = props;
  const { watch, getValues, setValue } = useFormContext<FormData>();
  const programName = watch('name');
  const topicsFieldArray = useFieldArray<FormData, 'topics', 'key'>({
    name: 'topics',
    keyName: 'key',
  });
  const tags = useMemo(
    () =>
      uniqBy(
        topicsFieldArray.fields?.flatMap((r) => r.tags ?? []),
        'id'
      ).sort((a, b) => a.name.localeCompare(b.name)),
    [topicsFieldArray.fields]
  );

  const refreshTopicsOrder = () => {
    const topics = getValues('topics') || [];
    const roundIds = topics.map((t) => t.id);
    const extensions: ModelsWaterCoolerProgramExtensions = {
      ...program.extensions,
      roundIds,
    };
    apiService.program.updateProgram(program.id, {
      name: program.name,
      extensions,
    });
  };

  const handleAddTopic = async (
    req: WaterCoolerTopicFormData,
    index: number
  ) => {
    const extensions: ModelsWaterCoolerRoundExtensions = {
      text: req.text,
      media: req.media,
    };
    const round = (
      await apiService.program.createRound(program.id, {
        tags: req.tags.map((t) => t.name),
        extensions,
      })
    ).data.programRound;
    const topic = WaterCoolerProgramUtils.BuildTopic(
      round as WaterCoolerProgramRound
    );
    topicsFieldArray.insert(index, topic);
    refreshTopicsOrder();
  };

  const handleDeleteRound = async (topic: WaterCoolerTopic, index: number) => {
    await apiService.program.deleteRound(program.id, topic.id);
    topicsFieldArray.remove(index);
    refreshTopicsOrder();
  };

  const handleUpdateRound = async (
    topic: WaterCoolerTopic,
    req: WaterCoolerTopicFormData,
    index: number
  ) => {
    const extensions: ModelsWaterCoolerRoundExtensions = {
      text: req.text,
      media: req.media,
    };
    const round = (
      await apiService.program.updateRound(program.id, topic.id, {
        tags: req.tags.map((t) => t.name),
        extensions,
      })
    ).data.programRound;
    const updated = WaterCoolerProgramUtils.BuildTopic(
      round as WaterCoolerProgramRound
    );
    topicsFieldArray.update(index, updated);
    refreshTopicsOrder();
  };

  const handleMoveRound = async (from: number, to: number) => {
    topicsFieldArray.move(from, to);
    refreshTopicsOrder();
  };

  const handleShuffleTopics = async () => {
    const updated = shuffle(topicsFieldArray.fields);
    setValue('topics', updated);
    refreshTopicsOrder();
  };

  return (
    <section className='flex flex-col gap-10 w-full'>
      <Controller
        name='tagSettings.selectedTagIds'
        render={({ field: { value, onChange } }) => (
          <ProgramCategoriesEditor
            programName={programName}
            tags={tags}
            selectedTagIds={value || []}
            onChange={onChange}
          />
        )}
      />

      <WaterCoolerTopicQueue
        context='program'
        programId={program.id}
        topics={topicsFieldArray.fields}
        editable
        onAdd={handleAddTopic}
        onUpdate={handleUpdateRound}
        onDelete={handleDeleteRound}
        onMove={handleMoveRound}
        onShuffle={handleShuffleTopics}
      />
    </section>
  );
}

type FormData = BasicProgramFormData & {
  topics?: WaterCoolerTopic[];
};

function makeDefaultValues(
  program: WaterCoolerProgram,
  rounds?: WaterCoolerProgramRound[]
): FormData {
  const topics: WaterCoolerTopic[] = [];
  for (const roundId of program.extensions?.roundIds || []) {
    const round = rounds?.find((r) => r.id === roundId);
    if (!round) continue;
    topics.push(WaterCoolerProgramUtils.BuildTopic(round));
  }
  return {
    name: program.name,
    basicSettings: program.basicSettings,
    promotionalAssets: program.promotionalAssets,
    tagSettings: program.tagSettings,
    marketingMetadata: program.marketingMetadata,
    topics,
  };
}

export function WaterCoolerProgramEditor(props: {
  program: WaterCoolerProgram;
  rounds?: WaterCoolerProgramRound[];
  backTo?: string | null;
}) {
  const { program, rounds, backTo } = props;

  const navigate = useNavigate();

  const form = useForm<FormData>({
    defaultValues: makeDefaultValues(program, rounds),
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onClose = () => {
    navigate(backTo || $path('/admin/programs/v2'));
  };

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: FormData) => {
    const links = WaterCoolerProgramUtils.BuildTopicLinks(data.topics || []);
    const extensions: ModelsWaterCoolerProgramExtensions = {
      ...program.extensions,
      roundIds: links.map((l) => l.roundId),
    };
    const resp = await apiService.program.updateProgram(program.id, {
      name: data.name,
      basicSettings: data.basicSettings ?? undefined,
      promotionalAssets: data.promotionalAssets ?? undefined,
      tagSettings: ProgramUtils.ConvertTagSettingsDtoToModel(data.tagSettings),
      marketingMetadata: data.marketingMetadata ?? undefined,
      extensions,
    });
    return resp.data.program;
  });

  const onSubmit = () => {
    form.handleSubmit(async (data: FormData) => {
      const resp = await submit(data);
      if (!resp) return;
      onClose();
    })();
  };

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  return (
    <ProgramEditorLayout
      type={program.type}
      program={program}
      onCancel={onClose}
      onSubmit={onSubmit}
      disabledReason={disabledReason}
      errors={errors}
      resetErrors={resetSubmit}
      setIsUploading={setIsUploading}
      {...form}
    >
      <FormProvider {...form}>
        <div className='flex flex-col gap-15 w-full text-white p-10'>
          <WaterCoolerProgramMessageCampaign program={program} />

          <TopicSection program={program} />
        </div>
      </FormProvider>
    </ProgramEditorLayout>
  );
}
