import { useSearchParams } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import uniqBy from 'lodash/uniqBy';
import { Fragment, useState } from 'react';
import {
  Controller,
  useFieldArray,
  type UseFieldArrayReturn,
  useForm,
  type UseFormReturn,
} from 'react-hook-form';

import {
  type DtoProgram,
  type DtoTag,
  EnumsProgramRoundStatus,
  type ModelsCalendarRoundExtensions,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import {
  type CalendarProgramRound,
  castProgramRound,
} from '../../../types/program';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { type Action, ActionSheet } from '../../ActionSheet';
import { BreadcrumbChain, Breadcrumbs } from '../../Breadcrumbs';
import { DateTimePicker } from '../../common/DateTimePicker';
import { GamePackSelect } from '../../Game/GameCenter/GamePackSelect';
import { CopyIcon } from '../../icons/CopyIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { EditIcon } from '../../icons/EditIcon';
import { ReplaceIcon } from '../../icons/ReplaceIcon';
import { Loading } from '../../Loading';
import { MessageLogicListAdmin } from '../../Message';
import { useRevalidateMessageLogics } from '../../Message/hooks';
import { TagPicker } from '../../Tagging';
import { ProgramCategoriesEditor } from '../ProgramCategories';
import { ProgramEditorLayout } from '../ProgramEditorLayout';
import { type BasicProgramFormData } from '../types';
import { ProgramUtils } from '../utils';
import { useMessageCampaignProgramRoundCalendar } from './CalendarProgramMessages';
import { CalendarProgramRoundEntry } from './CalendarProgramRoundEntry';

type FormData = BasicProgramFormData & {
  rounds?: CalendarProgramRound[];
};

type FormProps = UseFormReturn<FormData>;

type ActionSheetKeys = 'edit' | 'duplicate' | 'delete';

function RoundList(
  props: FormProps & {
    roundFieldArray: UseFieldArrayReturn<FormData, 'rounds', 'key'>;
    timezone: string;
    onAdd: () => void;
    onEdit: (round: CalendarProgramRound) => void;
    onDuplicate: (round: CalendarProgramRound) => void;
    onDelete: (round: CalendarProgramRound, index: number) => void;
  }
) {
  const { roundFieldArray, timezone } = props;
  const programName = props.watch('name');
  const selectedTagIds = props.watch('tagSettings.selectedTagIds');
  const [applyTagFilter, setApplyTagFilter] = useState<boolean>(false);
  const [asc, setAsc] = useState(true);

  const rounds = roundFieldArray.fields
    .filter((r) => {
      if (!applyTagFilter) return true;
      if (!selectedTagIds) return false;
      return !!r.tags?.find((t) => selectedTagIds.includes(t.id));
    })
    .sort((a, b) => {
      if (asc) {
        return a.startedAt?.localeCompare(b.startedAt ?? '') ?? 0;
      } else {
        return b.startedAt?.localeCompare(a.startedAt ?? '') ?? 0;
      }
    });

  const makeAction = (
    round: CalendarProgramRound,
    index: number
  ): Action<ActionSheetKeys>[] => {
    const actions: Action<ActionSheetKeys>[] = [];

    actions.push({
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit',
      onClick: () => props.onEdit(round),
    });
    actions.push(
      {
        kind: 'button',
        key: 'duplicate',
        icon: <CopyIcon />,
        text: 'Duplicate',
        onClick: () => props.onDuplicate(round),
      },
      {
        kind: 'button',
        key: 'delete',
        icon: <DeleteIcon />,
        text: 'Delete',
        className: 'text-red-002',
        onClick: () => props.onDelete(round, index),
      }
    );
    return actions;
  };

  return (
    <div className='flex flex-col gap-4'>
      <header className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          <button
            type='button'
            className='btn flex items-center gap-1'
            onClick={() => setAsc(!asc)}
          >
            <div className='font-bold'>List of {programName || 'Rounds'}</div>
            <ReplaceIcon />
          </button>
          <label className='text-2xs flex items-center gap-1'>
            Filter with Selected Categories
            <input
              type='checkbox'
              className='checkbox-dark'
              checked={applyTagFilter}
              onChange={(e) => setApplyTagFilter(e.target.checked)}
            />
          </label>
        </div>
        <button
          type='button'
          className='text-primary font-bold'
          onClick={props.onAdd}
        >
          + Add New
        </button>
      </header>
      <div className='flex flex-col w-full gap-2'>
        {rounds.map((r, i) => (
          <CalendarProgramRoundEntry
            key={r.id}
            round={r}
            timezone={timezone}
            action={
              <ActionSheet<ActionSheetKeys>
                actions={makeAction(r, i)}
                placement='right'
              />
            }
          />
        ))}
      </div>
      {rounds.length === 0 && (
        <div className='w-full flex items-center justify-center text-secondary'>
          No Rounds
        </div>
      )}
    </div>
  );
}

function RoundEditor(
  props: {
    roundFieldIndex: number;
    timezone: string;
    onSave: () => Promise<void>;
    onCancel: () => void;
    onDelete: () => void;
  } & FormProps
) {
  const { roundFieldIndex, timezone } = props;

  const round = props.watch(`rounds.${roundFieldIndex}`);

  const programBasicSettings = props.watch('basicSettings');
  const readonly = ProgramUtils.IsRoundStarted(round);

  const breadcrumbs = new BreadcrumbChain()
    .add(
      'list',
      <button type='button' onClick={props.onCancel}>
        Round List
      </button>
    )
    .add('detail', <div>Edit Round Details</div>);

  const campaign = useMessageCampaignProgramRoundCalendar({
    programBasicSettings,
    round,
  });
  const revalidateMessageLogics = useRevalidateMessageLogics(campaign.id);

  const {
    call: handleSubmit,
    state: {
      state: { isRunning: isSubmitting },
    },
  } = useLiveAsyncCall(async () => {
    await props.onSave();
    await revalidateMessageLogics();
  });

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-2xl'>
        <Breadcrumbs chain={breadcrumbs} />
      </div>
      {readonly && (
        <div className='text-sms text-tertiary'>
          This round has started or completed, it's readonly now.
        </div>
      )}
      <div className='w-full'>
        <div className='font-bold mb-2'>Round Name</div>
        <Controller
          name={`rounds.${roundFieldIndex}.label`}
          control={props.control}
          rules={{ maxLength: 100, required: true }}
          render={({ field, fieldState }) => (
            <input
              {...field}
              className={
                fieldState.error ? 'field-error h-10 mb-0' : 'field h-10 mb-0'
              }
              placeholder='Max 100 characters'
              maxLength={100}
              value={field.value ?? ''}
              disabled={readonly}
            />
          )}
        />
      </div>
      <div className='w-full'>
        <div className='font-bold mb-2'>Game Pack</div>
        <Controller
          control={props.control}
          name={`rounds.${roundFieldIndex}.extensions.gamePack`}
          render={({ field: { onChange, value }, fieldState }) => (
            <GamePackSelect
              value={fromDTOGamePack(value)}
              onChange={onChange}
              isError={!!fieldState.error}
              isClearable
              disabled={readonly}
            />
          )}
        />
      </div>
      <div className='w-full'>
        <div className='mb-2 font-bold'>Start Date</div>
        <Controller
          rules={{ required: true }}
          control={props.control}
          name={`rounds.${roundFieldIndex}.startedAt`}
          render={({ field: { onChange, value } }) => (
            <DateTimePicker
              value={{
                date: value ? new Date(value) : new Date(),
                timezone,
              }}
              onChange={(val) => onChange(val.date.toISOString())}
              disableTimezonePicker
              disabled={readonly}
            />
          )}
        />
      </div>
      <div>
        <div className='mb-2 font-bold'>Categories</div>
        <Controller
          name={`rounds.${roundFieldIndex}.tags`}
          control={props.control}
          render={({ field }) => (
            <TagPicker
              onChange={(tags) => {
                field.onChange(tags);
              }}
              tags={field.value ?? undefined}
              creatable
              multi={true}
              placeholder='Add existing or create new Categories'
              disabled={readonly}
            />
          )}
        />
      </div>

      <div className='mt-3 flex items-center gap-5'>
        <button
          type='button'
          className='btn-primary px-4 h-10 flex justify-center items-center'
          onClick={handleSubmit}
          disabled={isSubmitting || readonly}
        >
          {isSubmitting && (
            <Loading text='' containerClassName='mr-2' imgClassName='w-5 h-5' />
          )}
          {round.status === 'configuring' ? 'Schedule Round' : 'Save Round'}
        </button>
        <button
          type='button'
          className='btn-delete px-4 h-10'
          onClick={props.onDelete}
        >
          Delete Round
        </button>
      </div>

      <div className='border-b border-secondary my-5'></div>

      <MessageLogicListAdmin campaign={campaign} />
    </div>
  );
}

function CategoryEditor(
  props: FormProps & {
    tags: DtoTag[];
  }
) {
  const { watch } = props;
  const programName = watch('name');
  const tags = uniqBy(props.tags, 'id').sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Controller
      name='tagSettings.selectedTagIds'
      control={props.control}
      render={({ field }) => {
        return (
          <ProgramCategoriesEditor
            programName={programName}
            tags={tags}
            selectedTagIds={field.value ?? []}
            onChange={field.onChange}
          />
        );
      }}
    />
  );
}

function useEditingRoundId(): [string | null, (val: string | null) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const editingRoundId = searchParams.get('editingRoundId');
  const setEditingRoundId = useLiveCallback((val: string | null) => {
    if (val) {
      searchParams.set('editingRoundId', val);
    } else {
      searchParams.delete('editingRoundId');
    }
    setSearchParams(searchParams);
  });
  return [editingRoundId, setEditingRoundId];
}

export function CalendarProgramEditor(props: {
  program: DtoProgram;
  rounds?: CalendarProgramRound[];
  backTo?: string | null;
}) {
  const { program, rounds, backTo } = props;
  const timezone = 'America/New_York';
  const form = useForm<FormData>({
    defaultValues: {
      name: program.name,
      basicSettings: program.basicSettings,
      tagSettings: program.tagSettings,
      marketingMetadata: program.marketingMetadata,
      rounds: rounds ?? [],
    },
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const roundFieldArray = useFieldArray({
    control: form.control,
    name: 'rounds',
    keyName: 'key',
  });
  const [editingRoundId, setEditingRoundId] = useEditingRoundId();
  const editingRoundIndex = editingRoundId
    ? roundFieldArray.fields.findIndex((r) => r.id === editingRoundId)
    : null;
  const allTags = roundFieldArray.fields.flatMap((r) => r.tags ?? []);

  const onClose = () => {
    navigate(backTo || '/admin/programs/v2');
  };

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: FormData) => {
    const resp = await apiService.program.updateProgram(program.id, {
      name: data.name,
      basicSettings: data.basicSettings ?? undefined,
      tagSettings: ProgramUtils.ConvertTagSettingsDtoToModel(data.tagSettings),
      marketingMetadata: data.marketingMetadata ?? undefined,
    });
    return resp.data.program;
  });

  const onSubmit = () => {
    form.handleSubmit(async (data: FormData) => {
      const resp = await submit(data);
      if (!resp) return;
      onClose();
    })();
  };

  const onAddRound = async () => {
    const resp = await apiService.program.createRound(program.id, {
      label: 'New Round',
      startedAt: new Date().toISOString(),
    });
    roundFieldArray.prepend(
      castProgramRound<CalendarProgramRound>(resp.data.programRound)
    );
    setEditingRoundId(resp.data.programRound.id);
  };

  const onDeleteRound = async (round: CalendarProgramRound, index: number) => {
    await apiService.program.deleteRound(round.parentId, round.id);
    setEditingRoundId(null);
    roundFieldArray.remove(index);
  };

  const onUpdateRound = async (round: CalendarProgramRound, index: number) => {
    const extensions: ModelsCalendarRoundExtensions = {
      packId: round.extensions?.gamePack?.id,
    };
    const resp = await apiService.program.updateRound(
      round.parentId,
      round.id,
      {
        label: round.label ?? undefined,
        startedAt: round.startedAt ?? undefined,
        tags: round.tags?.map((t) => t.name),
        extensions: extensions,
        status:
          round.status === 'configuring'
            ? EnumsProgramRoundStatus.ProgramRoundStatusScheduled
            : undefined,
      }
    );
    roundFieldArray.update(
      index,
      castProgramRound<CalendarProgramRound>(resp.data.programRound)
    );
  };

  const onDuplicateRound = async (round: CalendarProgramRound) => {
    const resp = await apiService.program.duplicateRound(program.id, round.id);
    roundFieldArray.prepend(
      castProgramRound<CalendarProgramRound>(resp.data.programRound)
    );
    setEditingRoundId(resp.data.programRound.id);
  };

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning || !!editingRoundId
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  return (
    <ProgramEditorLayout
      type={program.type}
      onCancel={onClose}
      onSubmit={onSubmit}
      disabledReason={disabledReason}
      errors={errors}
      resetErrors={resetSubmit}
      setIsUploading={setIsUploading}
      {...form}
    >
      <div className='flex flex-col gap-10 w-full text-white p-10'>
        {editingRoundIndex !== null ? (
          <RoundEditor
            roundFieldIndex={editingRoundIndex}
            timezone={timezone}
            onCancel={() => setEditingRoundId(null)}
            onDelete={() =>
              onDeleteRound(
                roundFieldArray.fields[editingRoundIndex],
                editingRoundIndex
              )
            }
            onSave={async () => {
              const valid = await form.trigger(`rounds.${editingRoundIndex}`);
              if (!valid) return;
              await onUpdateRound(
                form.getValues(`rounds.${editingRoundIndex}`),
                editingRoundIndex
              );
            }}
            {...form}
          />
        ) : (
          <Fragment>
            <CategoryEditor tags={allTags} {...form} />
            <RoundList
              roundFieldArray={roundFieldArray}
              timezone={timezone}
              onAdd={onAddRound}
              onEdit={(r) => setEditingRoundId(r.id)}
              onDuplicate={onDuplicateRound}
              onDelete={onDeleteRound}
              {...form}
            />
          </Fragment>
        )}
      </div>
    </ProgramEditorLayout>
  );
}
