import { Controller, type UseFormReturn } from 'react-hook-form';

import {
  type DtoProgram,
  EnumsMediaScene,
  type EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { fromMediaDTO } from '../../utils/api-dto';
import { CollapsibleSection } from '../common/CollapsibleSection';
import { useOpenMarkdownEditor } from '../MarkdownEditor';
import { MediaUploader } from '../MediaUploader/MediaUploader';
import { BasicProgramMarketingEditor } from './BasicProgramEditorMarketing';
import { useTriggerSendProgramPromotionModal } from './ProgramPromotion';
import { type BasicProgramFormData } from './types';

export type BasicProgramControlledProps = {
  program?: DtoProgram | null;
  type: EnumsProgramType;
  setIsUploading: (val: boolean) => void;
} & UseFormReturn<BasicProgramFormData>;

function NameField(props: BasicProgramControlledProps) {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Program Name</div>
      <Controller
        name='name'
        control={props.control}
        rules={{ maxLength: 50 }}
        render={({ field, fieldState }) => (
          <input
            {...field}
            className={
              fieldState.error ? 'field-error h-13 mb-0' : 'field h-13 mb-0'
            }
            placeholder='Max 50 characters'
            maxLength={50}
          />
        )}
      />
    </div>
  );
}

function CoverField(
  props: BasicProgramControlledProps & {
    setIsUploading: (val: boolean) => void;
  }
) {
  const { setIsUploading } = props;
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Program Cover</div>
      <Controller
        name='basicSettings.cover'
        control={props.control}
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneProgram}
            media={fromMediaDTO(field.value?.media)}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                mediaData: { id: media.id },
              });
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
          />
        )}
      />
    </div>
  );
}

function BannerField(
  props: BasicProgramControlledProps & {
    setIsUploading: (val: boolean) => void;
  }
) {
  const { setIsUploading } = props;
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Program Banner</div>
      <Controller
        name='basicSettings.banner'
        control={props.control}
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneProgram}
            media={fromMediaDTO(field.value?.media)}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                mediaData: { id: media.id },
              });
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
          />
        )}
      />
    </div>
  );
}

function TrailerField(
  props: BasicProgramControlledProps & {
    setIsUploading: (val: boolean) => void;
  }
) {
  const { setIsUploading } = props;
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Program Trailer</div>
      <Controller
        name='basicSettings.trailer'
        control={props.control}
        render={({ field }) => (
          <MediaUploader
            video
            scene={EnumsMediaScene.MediaSceneProgram}
            media={fromMediaDTO(field.value?.media)}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                mediaData: { id: media.id },
              });
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
          />
        )}
      />
    </div>
  );
}

function BackgroundField(
  props: BasicProgramControlledProps & {
    setIsUploading: (val: boolean) => void;
  }
) {
  const { setIsUploading } = props;
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Program Detail Background</div>
      <Controller
        name='basicSettings.background'
        control={props.control}
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneProgram}
            media={fromMediaDTO(field.value?.media)}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                mediaData: { id: media.id },
              });
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
          />
        )}
      />
    </div>
  );
}

function HeadlineField(props: BasicProgramControlledProps) {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Headline</div>
      <Controller
        name='basicSettings.headline'
        control={props.control}
        rules={{ maxLength: 200 }}
        render={({ field, fieldState }) => (
          <textarea
            {...field}
            className={` h-25 mb-0 resize-none ${
              fieldState.error ? 'field-error' : 'field'
            }`}
            placeholder='Max 200 characters'
            maxLength={200}
            value={field.value ?? ''}
          />
        )}
      />
    </div>
  );
}

function HeadlineColorField(props: BasicProgramControlledProps) {
  return (
    <Controller
      name='basicSettings.headlineColor'
      control={props.control}
      rules={{ maxLength: 200, pattern: /^#[0-9A-Fa-f]{6}$/ }}
      render={({ field, fieldState }) => (
        <div className='block w-full'>
          <div className='mb-2 font-bold'>Headline Color</div>
          <input
            value={field.value ?? ''}
            onChange={field.onChange}
            className={`w-full h-12.5 ${
              fieldState?.error ? 'field-error' : 'field'
            } mb-0`}
            maxLength={50}
            placeholder='#000000'
          ></input>
          {fieldState?.error && (
            <div className='mt-1 text-red-500 text-sms'>
              Please enter a valid hex color code
            </div>
          )}
        </div>
      )}
    />
  );
}

function ShortDescriptionField(props: BasicProgramControlledProps) {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Short Description</div>
      <Controller
        name='basicSettings.shortDescription'
        control={props.control}
        rules={{ maxLength: 200 }}
        render={({ field, fieldState }) => (
          <textarea
            {...field}
            className={` h-25 mb-0 resize-none ${
              fieldState.error ? 'field-error' : 'field'
            }`}
            placeholder='Max 200 characters'
            maxLength={200}
            value={field.value ?? ''}
          />
        )}
      />
    </div>
  );
}

function LongDescriptionField(props: BasicProgramControlledProps) {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Long Description</div>
      <Controller
        name='basicSettings.longDescription'
        control={props.control}
        rules={{ maxLength: 1000 }}
        render={({ field, fieldState }) => (
          <textarea
            {...field}
            className={` h-40 mb-0 ${
              fieldState.error ? 'field-error' : 'field'
            }`}
            placeholder='Max 1000 characters'
            maxLength={1000}
            value={field.value ?? ''}
          />
        )}
      />
    </div>
  );
}

export function RichDescriptionField(props: BasicProgramControlledProps) {
  const openMarkdownEditor = useOpenMarkdownEditor();
  return (
    <label>
      <div className='mb-2 font-bold'>Rich Description (Markdown)</div>
      <Controller
        name='basicSettings.richDescription'
        control={props.control}
        rules={{ maxLength: 3000 }}
        render={({ field, fieldState }) => (
          <div className='relative'>
            <textarea
              {...field}
              value={field.value ?? ''}
              className={`${
                fieldState.error ? 'field-error mb-0' : 'field mb-0'
              } h-30 py-2 scrollbar`}
              maxLength={3000}
              placeholder='Max 3000 characters'
            />
            {
              <button
                type='button'
                className='absolute right-2 bottom-1 text-xl'
                onClick={() =>
                  openMarkdownEditor({
                    initValue: field.value ?? '',
                    onSave: (val) => field.onChange(val),
                  })
                }
              >
                🅼
              </button>
            }
          </div>
        )}
      />
    </label>
  );
}

function PromotionalDescription(props: BasicProgramControlledProps) {
  const triggerSendProgramPromotionModal =
    useTriggerSendProgramPromotionModal();

  return (
    <div className='block w-full'>
      <div className='mb-2 flex justify-between items-center'>
        <p className='font-bold'>Promotional Description</p>
        {props.program && (
          <button
            type='button'
            className='btn text-primary text-sm underline cursor-pointer'
            onClick={() =>
              triggerSendProgramPromotionModal({
                onConfirm: async (data) => {
                  await apiService.promotion.sendProgram({
                    recipientId: data.recipient?.uid ?? '',
                    programId: props.program?.id ?? '',
                  });
                },
              })
            }
          >
            Send Test
          </button>
        )}
      </div>
      <Controller
        name='promotionalAssets.description'
        control={props.control}
        rules={{ maxLength: 1000 }}
        render={({ field, fieldState }) => (
          <textarea
            {...field}
            className={`h-40 mb-0 ${
              fieldState.error ? 'field-error' : 'field'
            }`}
            placeholder='Max 1000 characters'
            maxLength={1000}
            value={field.value ?? ''}
          />
        )}
      />
    </div>
  );
}

export function BasicProgramEditor(props: BasicProgramControlledProps) {
  return (
    <div className='flex flex-col gap-4 my-4'>
      <div className='text-2xl font-medium'>
        <span className='capitalize'>{props.type}</span> Config
      </div>
      <NameField {...props} />
      <CoverField {...props} />
      <BannerField {...props} />
      <TrailerField {...props} />
      <BackgroundField {...props} />
      <HeadlineField {...props} />
      <HeadlineColorField {...props} />
      <ShortDescriptionField {...props} />
      <LongDescriptionField {...props} />
      <RichDescriptionField {...props} />
      <PromotionalDescription {...props} />
      <CollapsibleSection title='Marketing Meta Data' defaultOpened={false}>
        <BasicProgramMarketingEditor {...props} />
      </CollapsibleSection>
    </div>
  );
}
