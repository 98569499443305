import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import {
  EnumsMessageCampaignType,
  EnumsMessageTarget,
  EnumsMessageTriggerEventName,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { type CelebrationsProgram } from '../../../types/program';
import {
  type MessageCampaign,
  MessageLogicListAdmin,
  type MessageTriggerConfig,
  type MessageVars,
} from '../../Message';
import { useUser } from '../../UserContext';

export function useMessageCampaignProgramActivate(props: {
  program: CelebrationsProgram;
}) {
  const { program } = props;

  const user = useUser();

  const trigger: MessageTriggerConfig = useMemo(
    () => ({
      types: [EnumsMessageTriggerType.MessageTriggerTypeTimeOffset],
    }),
    []
  );

  const targets = useCallback(() => {
    return [
      EnumsMessageTarget.MessageTargetChannelCreator,
      EnumsMessageTarget.MessageTargetChannel,
      // EnumsMessageTarget.MessageTargetChannelMembers,
      // EnumsMessageTarget.MessageTargetOrgAdmins,
    ];
  }, []);

  const tags = useLiveCallback(
    (_trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      return [];
    }
  );

  const vars = useCallback(() => {
    const vars: MessageVars = {
      text: {
        recipientName: `<@${user.username}>`,
        programDetailsLink: window.origin,
        programAdminLink: window.origin,
        channelName: '#lunapark',
        nextRoundDate: format(new Date(), 'EEE, MMM do'),
      },
      media: {
        topicMedia: null,
      },
      condition: {},
    };
    return vars;
  }, [user.username]);

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramLinkActivated,
      id: program.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [program.id, targets, trigger, tags, vars]
  );
}

export function useMessageCampaignProgramCelebrations(props: {
  program: CelebrationsProgram;
}): MessageCampaign {
  const { program } = props;
  const user = useUser();

  const trigger: MessageTriggerConfig = useMemo(
    () => ({
      types: [EnumsMessageTriggerType.MessageTriggerTypeEventDriven],
      events: [
        EnumsMessageTriggerEventName.MessageTriggerEventNameProgramActivated,
      ],
    }),
    []
  );

  const targets = useCallback(() => {
    return [EnumsMessageTarget.MessageTargetChannelMembers];
  }, []);

  const tags = useLiveCallback(
    (_trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      return [];
    }
  );

  const vars = useCallback(() => {
    const vars: MessageVars = {
      text: {
        recipientName: `<@${user.username}>`,
        channelName: '#lunapark',
        savedDates: `🎂 Your birthday is not set.\n💼 Your work start date is set to Jan 2, 2006.`,
      },
      media: {},
      condition: {
        isBirthdaySet: false,
        isWorkStartDateSet: false,
      },
    };
    return vars;
  }, [user.username]);

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
      id: program.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [program.id, targets, trigger, tags, vars]
  );
}

export function CelebrationsProgramMessageCampaign(props: {
  program: CelebrationsProgram;
}) {
  const { program } = props;

  const activateCampaign = useMessageCampaignProgramActivate({
    program,
  });
  const roundCampaign = useMessageCampaignProgramCelebrations({
    program,
  });

  return (
    <section>
      <div className='text-2xl font-medium'>Messages</div>
      <div className='mt-5 flex flex-col gap-3'>
        <MessageLogicListAdmin campaign={activateCampaign} />
        <MessageLogicListAdmin campaign={roundCampaign} />
      </div>
    </section>
  );
}
