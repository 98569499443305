import { match } from 'ts-pattern';

import {
  type DtoMessageLogicV2,
  EnumsMessageCampaignType,
  EnumsMessageLogicStatus,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { ActionSheet } from '../ActionSheet';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';
import { EditIcon } from '../icons/EditIcon';
import { Loading } from '../Loading';
import { SwitcherControlled } from '../Switcher';
import { useMessageLogics } from './hooks';
import { type MessageCampaign } from './MessageCampaign';
import { useTriggerMessageLogicsEditorModal } from './MessageLogicEditor';
import {
  MessageConditionAndTemplateCell,
  MessageTargetCell,
  MessageTriggerCell,
} from './MessageLogicList';
import { MessageTriggerUtils } from './utils';

function MessageLogicCard(props: {
  logic: DtoMessageLogicV2;
  campaign: MessageCampaign;
  onRefresh: () => void;
}): JSX.Element {
  const { logic, campaign, onRefresh } = props;

  const triggerMessageLogicsEditorModal = useTriggerMessageLogicsEditorModal();

  const handleDelete = async () => {
    await apiService.message.deleteLogic(logic.id);
    onRefresh();
  };

  const handleEdit = async () => {
    triggerMessageLogicsEditorModal({
      campaign,
      logic,
      onConfirm: onRefresh,
    });
  };

  const handleActivate = async () => {
    await apiService.message.activateLogic(logic.id);
    onRefresh();
  };

  const handleDeactivate = async () => {
    await apiService.message.deactivateLogic(logic.id);
    onRefresh();
  };

  const handleDuplicate = async () => {
    await apiService.message.duplicateLogic(logic.id);
    onRefresh();
  };

  return (
    <div
      key={logic.id}
      className='w-full bg-modal rounded-xl border border-secondary
        px-5 py-2.5 flex justify-between items-center gap-1
      '
    >
      <MessageTargetCell target={logic.target} />

      <MessageConditionAndTemplateCell
        condition={logic.condition}
        template={logic.template}
      />

      <SwitcherControlled
        name={`message-logic-${logic.id}-status`}
        className=''
        checked={
          logic.status === EnumsMessageLogicStatus.MessageLogicStatusActive
        }
        onChange={(checked: boolean) =>
          checked ? handleActivate() : handleDeactivate()
        }
      />

      <MessageTriggerCell trigger={logic.trigger} />

      <ActionSheet
        btnSizingClassName='w-7.5 h-7.5'
        actions={[
          {
            kind: 'button',
            key: 'edit',
            text: 'Edit',
            icon: <EditIcon />,
            onClick: () => handleEdit(),
          },
          {
            kind: 'button',
            key: 'duplicate',
            text: 'Duplicate',
            icon: <DuplicateIcon />,
            onClick: () => handleDuplicate(),
          },
          {
            kind: 'button',
            key: 'delete',
            text: 'Delete',
            icon: <DeleteIcon />,
            onClick: () => handleDelete(),
          },
        ]}
      />
    </div>
  );
}

export function MessageLogicListAdmin(props: {
  campaign: MessageCampaign;
  label?: string;
}): JSX.Element {
  const { campaign } = props;

  const {
    data: logics,
    isLoading,
    mutate,
  } = useMessageLogics(campaign.type, campaign.id);
  const sorted = logics?.sort((a, b) =>
    MessageTriggerUtils.Compare(a.trigger, b.trigger)
  );

  const triggerMessageLogicsEditorModal = useTriggerMessageLogicsEditorModal();

  if (isLoading) return <Loading />;

  const handleCreate = () => {
    triggerMessageLogicsEditorModal({
      campaign,
      onConfirm: () => mutate(),
    });
  };

  return (
    <div className='w-full'>
      <header className='flex justify-between items-center'>
        <div className='font-bold'>
          {props.label ??
            match(campaign.type)
              .with(
                EnumsMessageCampaignType.MessageCampaignTypeProgramLinkActivated,
                () => 'Program Activate'
              )
              .with(
                EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
                () => 'Round Start'
              )
              .otherwise(() => 'Unknown')}
        </div>
        <button
          type='button'
          onClick={handleCreate}
          className='btn text-primary'
        >
          + Add New Message
        </button>
      </header>
      <div className='mt-3 w-full flex flex-col gap-2.5'>
        {sorted?.map((logic) => (
          <MessageLogicCard
            key={logic.id}
            logic={logic}
            campaign={campaign}
            onRefresh={() => mutate()}
          />
        ))}
      </div>
      {(!logics || logics.length === 0) && (
        <div className='w-full flex items-center justify-center text-secondary text-sms'>
          No Messages
        </div>
      )}
    </div>
  );
}
