import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { $path } from 'remix-routes';

import { type DtoProgram } from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { ProgramEditorLayout } from './ProgramEditorLayout';
import { type BasicProgramFormData } from './types';

type FormData = BasicProgramFormData;

export function DefaultProgramEditor(props: {
  program: DtoProgram;
  backTo?: string | null;
}) {
  const { program, backTo } = props;
  const form = useForm<FormData>({
    defaultValues: {
      name: program.name,
      basicSettings: program.basicSettings,
      marketingMetadata: program.marketingMetadata,
    },
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onClose = () => {
    navigate(backTo || $path('/admin/programs/v2'));
  };

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: FormData) => {
    const resp = await apiService.program.updateProgram(program.id, {
      name: data.name,
      basicSettings: data.basicSettings ?? undefined,
      marketingMetadata: data.marketingMetadata ?? undefined,
    });
    return resp.data.program;
  });

  const onSubmit = () => {
    form.handleSubmit(async (data: FormData) => {
      const resp = await submit(data);
      if (!resp) return;
      onClose();
    })();
  };

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  return (
    <ProgramEditorLayout
      type={program.type}
      onCancel={onClose}
      onSubmit={onSubmit}
      disabledReason={disabledReason}
      errors={errors}
      resetErrors={resetSubmit}
      setIsUploading={setIsUploading}
      {...form}
    ></ProgramEditorLayout>
  );
}
