import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { useFieldArray, useForm, type UseFormReturn } from 'react-hook-form';
import { $path } from 'remix-routes';

import {
  type DtoProgram,
  type ModelsProgramBasicSettings,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { apiService } from '../../../services/api-service';
import {
  type IntrosProgram,
  type IntrosProgramRound,
} from '../../../types/program';
import { MessageLogicListAdmin } from '../../Message';
import { ProgramEditorLayout } from '../ProgramEditorLayout';
import { type BasicProgramFormData } from '../types';
import { ProgramUtils } from '../utils';
import {
  useMessageCampaignIntrosProgramActivate,
  useMessageCampaignProgramRoundIntros,
} from './IntrosProgramMssages';
import { IntrosProgramUtils } from './utils';

type FormData = BasicProgramFormData & {
  rounds?: IntrosProgramRound[];
};

type FormProps = UseFormReturn<FormData>;

function ProgramActivateCampaign(props: { program: IntrosProgram }) {
  const { program } = props;
  const campaign = useMessageCampaignIntrosProgramActivate({ program });

  return <MessageLogicListAdmin campaign={campaign} />;
}

function RoundEntry(props: {
  round: IntrosProgramRound;
  programBasicSettings?: ModelsProgramBasicSettings | null;
}) {
  const { programBasicSettings, round } = props;
  const campaign = useMessageCampaignProgramRoundIntros({
    programBasicSettings,
    round,
  });

  return (
    <MessageLogicListAdmin
      campaign={campaign}
      label={IntrosProgramUtils.FormatRoundLabel(round)}
    />
  );
}

function RoundList(props: FormProps & { program: IntrosProgram }) {
  const roundFieldArray = useFieldArray({
    control: props.control,
    name: 'rounds',
    keyName: 'key',
  });
  const programBasicSettings = props.watch('basicSettings');
  const config = useInstance(() => IntrosProgramUtils.GetCadenceConfig());

  return (
    <div className='flex flex-col w-full gap-10'>
      <header className='text-2xl font-medium'>Message</header>
      <div className='flex flex-col w-full gap-10'>
        <ProgramActivateCampaign program={props.program} />

        {roundFieldArray.fields
          .filter((r) =>
            r.extensions?.frequency
              ? config.frequencies.includes(r.extensions?.frequency)
              : false
          )
          .map((r) => (
            <RoundEntry
              key={r.id}
              programBasicSettings={programBasicSettings}
              round={r}
            />
          ))}
      </div>
    </div>
  );
}

export function IntrosProgramEditor(props: {
  program: DtoProgram;
  rounds?: IntrosProgramRound[];
  backTo?: string | null;
}) {
  const { program, backTo } = props;
  const form = useForm<FormData>({
    defaultValues: {
      name: program.name,
      basicSettings: program.basicSettings,
      tagSettings: program.tagSettings,
      promotionalAssets: program.promotionalAssets,
      marketingMetadata: program.marketingMetadata,
      rounds: IntrosProgramUtils.SortRounds(props.rounds ?? []),
    },
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onClose = () => {
    navigate(backTo || $path('/admin/programs/v2'));
  };

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: FormData) => {
    const resp = await apiService.program.updateProgram(program.id, {
      name: data.name,
      basicSettings: data.basicSettings ?? undefined,
      promotionalAssets: data.promotionalAssets ?? undefined,
      marketingMetadata: data.marketingMetadata ?? undefined,
      tagSettings: ProgramUtils.ConvertTagSettingsDtoToModel(data.tagSettings),
    });
    return resp.data.program;
  });

  const onSubmit = () => {
    form.handleSubmit(async (data: FormData) => {
      const resp = await submit(data);
      if (!resp) return;
      onClose();
    })();
  };

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning
    ? 'processing'
    : isUploading
    ? 'loading'
    : null;

  return (
    <ProgramEditorLayout
      type={program.type}
      program={program}
      onCancel={onClose}
      onSubmit={onSubmit}
      disabledReason={disabledReason}
      errors={errors}
      resetErrors={resetSubmit}
      setIsUploading={setIsUploading}
      {...form}
    >
      <div className='flex flex-col gap-10 w-full text-white p-10'>
        <RoundList {...form} program={program} />
      </div>
    </ProgramEditorLayout>
  );
}
