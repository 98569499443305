import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  type DtoMessageLogicV2,
  type EnumsMessageTarget,
  EnumsMessageTemplateMediaSource,
  type ModelsMessageCondition,
  type ModelsMessageTemplateV2,
  type ModelsMessageTrigger,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { BannerWarningMessages } from '../common/BannerWarningMessage';
import { Modal } from '../common/Modal';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { HeaderLayout } from '../Header';
import { LPLogo } from '../icons/LPLogo';
import { type MessageCampaign } from './MessageCampaign';
import { MessageConditionEditor } from './MessageCondition';
import { MessageTagsEditor } from './MessageTags';
import { MessageTargetEditor } from './MessageTarget';
import { MessageTemplateEditor } from './MessageTemplate';
import {
  makeDefaultMessageTrigger,
  MessageTriggerEditor,
} from './MessageTrigger';

function Header(props: {
  onCancel: () => void;
  onSubmit: () => void;
  disabledReason: 'submitting' | 'uploading' | 'errors' | null;
}): JSX.Element {
  return (
    <HeaderLayout
      containerClassName={'bg-admin-red'}
      fill
      left={
        <div className='flex items-center gap-4'>
          <LPLogo type='admin' />
        </div>
      }
      right={
        <>
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <div className='relative'>
            <button
              className='btn-primary w-34 h-10 flex flex-row justify-center items-center'
              type='button'
              disabled={!!props.disabledReason}
              onClick={props.onSubmit}
            >
              {props.disabledReason === 'submitting' ? 'Saving' : 'Save'}
            </button>
          </div>
        </>
      }
    />
  );
}

export interface MessageLogicFormData {
  target: EnumsMessageTarget;
  trigger: ModelsMessageTrigger;
  condition: ModelsMessageCondition;
  template: ModelsMessageTemplateV2;
  tags: string[];
}

function defaultValues(
  campaign: MessageCampaign,
  logic?: DtoMessageLogicV2
): MessageLogicFormData {
  const trigger = logic?.trigger || makeDefaultMessageTrigger(campaign.trigger);
  const target = logic?.target || campaign.targets(trigger)[0];

  return {
    trigger,
    target,
    condition: logic?.condition || { expression: '' },
    template: logic?.template || {
      text: '',
      media: {
        source:
          EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload,
        mediaData: null,
        media: null,
      },
      buttons: [],
    },
    tags: logic?.tags ?? [],
  };
}

interface MessageLogicEditorProps {
  campaign: MessageCampaign;
  logic?: DtoMessageLogicV2;
  onConfirm: (logic: DtoMessageLogicV2) => void;
  onCancel: () => void;
}

function MessageLogicEditor(props: MessageLogicEditorProps): JSX.Element {
  const { logic, campaign, onConfirm, onCancel } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: defaultValues(campaign, logic),
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const {
    call: submit,
    state: { state: submitState, error: submitError },
    reset: resetSubmit,
  } = useLiveAsyncCall(async (data: MessageLogicFormData) => {
    if (!logic) {
      const resp = await apiService.message.createLogic({
        name: 'custom',
        target: data.target,
        trigger: data.trigger,
        condition: data.condition,
        template: data.template,
        tags: data.tags,
        campaignType: campaign.type,
        campaignId: campaign.id,
      });
      return resp.data.logic;
    }

    const resp = await apiService.message.updateLogic(logic.id, {
      target: data.target,
      trigger: data.trigger,
      condition: data.condition,
      template: data.template,
      tags: data.tags,
    });
    return resp.data.logic;
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    const logic = await submit(data);
    if (!logic) return;
    onConfirm(logic);
  });

  const errors: Error[] = [];
  if (submitError) errors.push(submitError);

  const disabledReason = errors.length
    ? 'errors'
    : submitState.isRunning
    ? 'submitting'
    : isUploading
    ? 'uploading'
    : null;

  const trigger = form.watch('trigger');
  const target = form.watch('target');
  const tags = form.watch('tags');

  const supportedTargets = useMemo(
    () => campaign.targets(trigger),
    [campaign, trigger]
  );
  const supportedTags = useMemo(
    () => campaign.tags(trigger, target),
    [campaign, target, trigger]
  );

  const vars = useMemo(
    () => campaign.vars(trigger, target, tags),
    [campaign, target, trigger, tags]
  );

  useEffect(() => {
    if (!supportedTargets.includes(target)) {
      form.setValue('target', supportedTargets[0]);
    }
  }, [form, supportedTargets, target]);

  return (
    <FormProvider {...form}>
      <div className='w-full h-full'>
        <Header
          onCancel={onCancel}
          onSubmit={handleSubmit}
          disabledReason={disabledReason}
        />
        <BannerWarningMessages errors={errors} reset={resetSubmit} />

        <main className='w-full p-12.5 text-white flex flex-col gap-10'>
          <section className='w-full'>
            <header className='text-2xl font-medium'>Message Logic</header>
            <main className='mt-5 flex flex-col gap-5'>
              <MessageTriggerEditor config={campaign.trigger} />
              <MessageTargetEditor supportedTargets={supportedTargets} />
              <MessageConditionEditor vars={vars.condition} />
              <MessageTagsEditor supportTags={supportedTags} />
            </main>
          </section>

          <MessageTemplateEditor
            vars={vars.text}
            mediaVars={vars.media}
            setIsUploading={setIsUploading}
          />
        </main>
      </div>
    </FormProvider>
  );
}

export function useTriggerMessageLogicsEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: {
      logic?: DtoMessageLogicV2;
      campaign: MessageCampaign;
      onConfirm?: (logic: DtoMessageLogicV2) => void;
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <Modal className='w-screen h-screen' borderStyle='none'>
            <MessageLogicEditor
              logic={props.logic}
              campaign={props.campaign}
              onConfirm={async (logic: DtoMessageLogicV2) => {
                p.internalOnConfirm();
                if (props.onConfirm) {
                  props.onConfirm(logic);
                }
              }}
              onCancel={p.internalOnCancel}
            />
          </Modal>
        ),
      });
    },
    [triggerModal]
  );
}
