import { add, format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  type DtoProgramRound,
  EnumsMessageCampaignType,
  EnumsMessageTarget,
  EnumsMessageTriggerEventName,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
  type ModelsProgramBasicSettings,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { type IntrosProgram } from '../../../types/program';
import {
  type MessageCampaign,
  type MessageTriggerConfig,
  type MessageVars,
} from '../../Message';
import { useUser } from '../../UserContext';

export function useMessageCampaignProgramRoundIntros(props: {
  programBasicSettings?: ModelsProgramBasicSettings | null;
  round: DtoProgramRound;
  programLink?: DtoChannelProgramLink;
  channel?: DtoChannel;
}): MessageCampaign {
  const { round, programBasicSettings, programLink } = props;

  const trigger: MessageTriggerConfig = useMemo(
    () => ({
      types: [
        EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
        EnumsMessageTriggerType.MessageTriggerTypeEventDriven,
      ],
      events: [
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupsAssigned,
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupMembersDownToOne,
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupRepaired,
      ],
    }),
    []
  );

  const targets = useCallback((trigger: ModelsMessageTrigger) => {
    if (
      trigger.type === EnumsMessageTriggerType.MessageTriggerTypeEventDriven
    ) {
      if (
        trigger.eventName ===
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupsAssigned
      ) {
        return [EnumsMessageTarget.MessageTargetGroups];
      }
      if (
        trigger.eventName ===
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupMembersDownToOne
      ) {
        return [EnumsMessageTarget.MessageTargetMember];
      }
      if (
        trigger.eventName ===
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupRepaired
      ) {
        return [EnumsMessageTarget.MessageTargetGroups];
      }
    }

    return [
      EnumsMessageTarget.MessageTargetOrgAdmins,
      EnumsMessageTarget.MessageTargetChannel,
      EnumsMessageTarget.MessageTargetGroups,
    ];
  }, []);

  const tags = useLiveCallback(
    (_trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      return [];
    }
  );

  const vars = useCallback(
    (
      trigger: ModelsMessageTrigger,
      target: EnumsMessageTarget,
      _tags: string[]
    ) => {
      const vars: MessageVars = {
        text: {
          recipientName: '<@participants1>',
          programLongDescription: programBasicSettings?.longDescription || '',
          programDetailsLink: programLink?.id
            ? `${window.origin}/programs/${programLink.id}/details`
            : window.origin,
          programAdminLink: programLink?.id
            ? `${window.origin}/programs/${programLink.id}/admin`
            : window.origin,
          channelName: '<#lunapark>',
          roundStartDate: format(new Date(), 'EEE, MMM do, yyyy'),
          roundEndDate: format(
            add(new Date(), { days: 7 }),
            'EEE, MMM do, yyyy'
          ),
          nextRoundDate: format(
            add(new Date(), { days: 7 }),
            'EEE, MMM do, yyyy'
          ),
          groupsCount: 30,
          groupsCompletedCount: 15,
          groupsCompletedPercent: '50%',
          playersCount: 80,
          playersCompletedCount: 50,
          playersCompletedPercent: '62.5%',
        },
        media: {},
        condition: {
          groupsCount: 0,
          groupsCompletedCount: 0,
          playersCount: 0,
          playersCompletedCount: 0,
        },
      };
      if (target === EnumsMessageTarget.MessageTargetGroups) {
        vars.text.groupGameLink = `${window.origin}/programs/groups/01HFYAYQ9PR2G078TZ61F9DC7H/play`;
        vars.text.groupCoordinatorName = '<@participants1>';
        vars.condition.isGroupCompleted = false;
      }
      if (
        trigger.eventName ===
        EnumsMessageTriggerEventName.MessageTriggerEventNameGroupMembersDownToOne
      ) {
        vars.condition.willBeRepaired = false;
      }
      return vars;
    },
    [programBasicSettings?.longDescription, programLink?.id]
  );

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramRound,
      id: round.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [round.id, targets, trigger, tags, vars]
  );
}

export function useMessageCampaignIntrosProgramActivate(props: {
  program: IntrosProgram;
}) {
  const { program } = props;

  const user = useUser();

  const trigger: MessageTriggerConfig = useMemo(
    () => ({
      types: [EnumsMessageTriggerType.MessageTriggerTypeTimeOffset],
    }),
    []
  );

  const targets = useCallback(() => {
    return [
      EnumsMessageTarget.MessageTargetChannelCreator,
      EnumsMessageTarget.MessageTargetChannel,
      // EnumsMessageTarget.MessageTargetChannelMembers,
      // EnumsMessageTarget.MessageTargetOrgAdmins,
    ];
  }, []);

  const tags = useLiveCallback(
    (_trigger: ModelsMessageTrigger, _target: EnumsMessageTarget) => {
      return [];
    }
  );

  const vars = useCallback(() => {
    const vars: MessageVars = {
      text: {
        recipientName: `<@${user.username}>`,
        programDetailsLink: window.origin,
        programAdminLink: window.origin,
        channelName: '#lunapark',
        nextRoundDate: format(new Date(), 'EEE, MMM do'),
      },
      media: {
        topicMedia: null,
      },
      condition: {},
    };
    return vars;
  }, [user.username]);

  return useMemo(
    () => ({
      type: EnumsMessageCampaignType.MessageCampaignTypeProgramLinkActivated,
      id: program.id,
      trigger,
      targets,
      tags,
      vars,
    }),
    [program.id, targets, trigger, tags, vars]
  );
}
